// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-our-ads-js": () => import("./../../../src/pages/about-our-ads.js" /* webpackChunkName: "component---src-pages-about-our-ads-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-how-to-how-to-oven-bake-bacon-js": () => import("./../../../src/pages/how-to/how-to-oven-bake-bacon.js" /* webpackChunkName: "component---src-pages-how-to-how-to-oven-bake-bacon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locator-js": () => import("./../../../src/pages/locator.js" /* webpackChunkName: "component---src-pages-locator-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-applewood-smoked-index-js": () => import("./../../../src/pages/products/applewood-smoked/index.js" /* webpackChunkName: "component---src-pages-products-applewood-smoked-index-js" */),
  "component---src-pages-products-double-smoked-bacon-index-js": () => import("./../../../src/pages/products/double-smoked-bacon/index.js" /* webpackChunkName: "component---src-pages-products-double-smoked-bacon-index-js" */),
  "component---src-pages-products-hickory-smoked-index-js": () => import("./../../../src/pages/products/hickory-smoked/index.js" /* webpackChunkName: "component---src-pages-products-hickory-smoked-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-maple-flavored-index-js": () => import("./../../../src/pages/products/maple-flavored/index.js" /* webpackChunkName: "component---src-pages-products-maple-flavored-index-js" */),
  "component---src-pages-recipes-bacon-and-ale-skillet-braised-chicken-thighs-index-js": () => import("./../../../src/pages/recipes/bacon-and-ale-skillet-braised-chicken-thighs/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-and-ale-skillet-braised-chicken-thighs-index-js" */),
  "component---src-pages-recipes-bacon-and-venison-scramble-index-js": () => import("./../../../src/pages/recipes/bacon-and-venison-scramble/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-and-venison-scramble-index-js" */),
  "component---src-pages-recipes-bacon-backyard-corn-index-js": () => import("./../../../src/pages/recipes/bacon-backyard-corn/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-backyard-corn-index-js" */),
  "component---src-pages-recipes-bacon-brunch-burger-index-js": () => import("./../../../src/pages/recipes/bacon-brunch-burger/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-brunch-burger-index-js" */),
  "component---src-pages-recipes-bacon-grilled-cheese-sandwiches-smothered-with-bacon-sloppy-joe-index-js": () => import("./../../../src/pages/recipes/bacon-grilled-cheese-sandwiches-smothered-with-bacon-sloppy-joe/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-grilled-cheese-sandwiches-smothered-with-bacon-sloppy-joe-index-js" */),
  "component---src-pages-recipes-bacon-hazelnut-farro-salad-with-bourbon-bacon-vinaigrette-index-js": () => import("./../../../src/pages/recipes/bacon-hazelnut-farro-salad-with-bourbon-bacon-vinaigrette/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-hazelnut-farro-salad-with-bourbon-bacon-vinaigrette-index-js" */),
  "component---src-pages-recipes-bacon-shrimp-and-crawfish-skewers-index-js": () => import("./../../../src/pages/recipes/bacon-shrimp-and-crawfish-skewers/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-shrimp-and-crawfish-skewers-index-js" */),
  "component---src-pages-recipes-bacon-wrapped-beer-can-duck-and-honey-beer-glaze-index-js": () => import("./../../../src/pages/recipes/bacon-wrapped-beer-can-duck-and-honey-beer-glaze/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-wrapped-beer-can-duck-and-honey-beer-glaze-index-js" */),
  "component---src-pages-recipes-bacon-wrapped-litl-smokies-sausages-index-js": () => import("./../../../src/pages/recipes/bacon-wrapped-litl-smokies-sausages/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-wrapped-litl-smokies-sausages-index-js" */),
  "component---src-pages-recipes-bacon-wrapped-pickles-index-js": () => import("./../../../src/pages/recipes/bacon-wrapped-pickles/index.js" /* webpackChunkName: "component---src-pages-recipes-bacon-wrapped-pickles-index-js" */),
  "component---src-pages-recipes-brand-klondike-bar-bonbons-index-js": () => import("./../../../src/pages/recipes/brand-klondike-bar-bonbons/index.js" /* webpackChunkName: "component---src-pages-recipes-brand-klondike-bar-bonbons-index-js" */),
  "component---src-pages-recipes-brand-klondike-bar-brittle-index-js": () => import("./../../../src/pages/recipes/brand-klondike-bar-brittle/index.js" /* webpackChunkName: "component---src-pages-recipes-brand-klondike-bar-brittle-index-js" */),
  "component---src-pages-recipes-brand-klondike-bar-sandwich-index-js": () => import("./../../../src/pages/recipes/brand-klondike-bar-sandwich/index.js" /* webpackChunkName: "component---src-pages-recipes-brand-klondike-bar-sandwich-index-js" */),
  "component---src-pages-recipes-candy-bacon-hearts-index-js": () => import("./../../../src/pages/recipes/candy-bacon-hearts/index.js" /* webpackChunkName: "component---src-pages-recipes-candy-bacon-hearts-index-js" */),
  "component---src-pages-recipes-index-js": () => import("./../../../src/pages/recipes/index.js" /* webpackChunkName: "component---src-pages-recipes-index-js" */),
  "component---src-pages-recipes-loaded-cornbread-index-js": () => import("./../../../src/pages/recipes/loaded-cornbread/index.js" /* webpackChunkName: "component---src-pages-recipes-loaded-cornbread-index-js" */),
  "component---src-pages-recipes-maple-flavored-bacon-waffles-index-js": () => import("./../../../src/pages/recipes/maple-flavored-bacon-waffles/index.js" /* webpackChunkName: "component---src-pages-recipes-maple-flavored-bacon-waffles-index-js" */),
  "component---src-pages-recipes-southern-fried-bacon-tacos-index-js": () => import("./../../../src/pages/recipes/southern-fried-bacon-tacos/index.js" /* webpackChunkName: "component---src-pages-recipes-southern-fried-bacon-tacos-index-js" */),
  "component---src-pages-recipes-steak-cut-heritage-cured-bacon-lollipops-index-js": () => import("./../../../src/pages/recipes/steak-cut-heritage-cured-bacon-lollipops/index.js" /* webpackChunkName: "component---src-pages-recipes-steak-cut-heritage-cured-bacon-lollipops-index-js" */),
  "component---src-pages-recipes-steamed-bao-buns-bacon-index-js": () => import("./../../../src/pages/recipes/steamed-bao-buns-bacon/index.js" /* webpackChunkName: "component---src-pages-recipes-steamed-bao-buns-bacon-index-js" */),
  "component---src-pages-recipes-the-tex-bacon-and-rice-bowl-index-js": () => import("./../../../src/pages/recipes/the-tex-bacon-and-rice-bowl/index.js" /* webpackChunkName: "component---src-pages-recipes-the-tex-bacon-and-rice-bowl-index-js" */),
  "component---src-pages-recipes-ultimate-bacon-lovers-sandwich-index-js": () => import("./../../../src/pages/recipes/ultimate-bacon-lovers-sandwich/index.js" /* webpackChunkName: "component---src-pages-recipes-ultimate-bacon-lovers-sandwich-index-js" */),
  "component---src-pages-recipes-ultimate-blt-index-js": () => import("./../../../src/pages/recipes/ultimate-blt/index.js" /* webpackChunkName: "component---src-pages-recipes-ultimate-blt-index-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

